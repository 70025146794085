<template>
  <div
    class="promotion-img"
  >
    <CropImageContainer
      :img-src="transformImg({ img: goodsImg })"
      fixed-ratio="3-4"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { transformImg } from '@shein/common-function'
import { CropImageContainer } from 'public/src/pages/components/CropImageContainer/index.js'

export default defineComponent({
  name: 'PromotionImg',
  components: {
    CropImageContainer,
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  computed: {
    goodsImg() {
      return this.item.goods_img || ''
    }
  },
  methods: {
    transformImg,
  }
})
</script>

<style lang="less" scoped>
.promotion-img {
  width: 2.56rem;
  height: 3.41rem;
  overflow: hidden;
  img{
    width: 100%;
  }
}
</style>
