<template>
  <div
    class="header"
    :style="{
      '--sh-background-image': bgs.sh,
      '--rw-background-image': bgs.rw || bgs.sh,
      '--background-size': backgroundSize || 'cover'
    }"
  >
    <div class="title-content">
      <img
        src="http://img.ltwebstatic.com/images3_acp/2024/09/11/c4/172604568974193a6e6ad657b23089219a17c84c81.png" 
        alt="" 
        width="10" 
        height="21"
        :class="{
          'is-opacity': promotionType !== 'clubGift',
        }"
      />
      <span
        :class="[
          'title',
          {
            'title_club-gift': promotionType === 'clubGift'
          }
        ]"
      >
        {{ title }}
      </span>
      <img 
        src="http://img.ltwebstatic.com/images3_acp/2024/09/11/07/1726045676339d20b41b2c6f764d2a1e9b6443c8cf.png" 
        alt="" 
        width="10" 
        height="21"
        :class="{
          'is-opacity': promotionType !== 'clubGift',
        }"
      />
    </div>
    <div class="close-icon">
      <Icon
        aria-hidden="true"
        name="sui_icon_close_18px"
        size="16px"
        color="#fff"
        @click="handleClose"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'

export default defineComponent({
  name: 'PromotionHeader',
  // 组件注册
  components: {
    Icon
  },
  // 组件传参
  props: {
    title: {
      type: String,
      default: ''
    },
    backgroundImages: {
      type: Object,
      default: () => ({})
    },
    backgroundSize: {
      type: String,
      default: 'cover'
    },
    promotionType: {
      type: String,
      default: ''
    }
  },
  computed: {
    bgs() {
      return {
        sh: 'url("/pwa_dist/images/cart/promotion/promotion_bg-6ee45b9789.png")',
        rw: 'url("/pwa_dist/rw_images/cart/promotion/promotion_bg-ffac23b690.png")',
        ...(this.backgroundImages || {})
      }
    }
  },

  // 方法实现
  methods: {
    handleClose() {
      this.$emit('close')
    }
  },
})
</script>

<style lang='less' scoped>
.header{
  height: 204/75rem;
  background-size: cover;
  position: relative;
  flex-shrink: 0;
  &::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: @zindex-zero;
    background-size: var(--background-size);
    background-repeat: no-repeat;
    transform: rotateY(0deg) translateZ(0px) /* rtl: rotateY(180deg) translateZ(0px)*/;
    /* sh:begin*/
    background-image: var(--sh-background-image);
    /* sh:end */
    /* rw:begin */
    background-image: var(--rw-background-image);
    /* rw:end */
  }
  .title-content {
    position: relative;
    padding: 0 48/75rem;
    height: 114/75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    direction: ltr /* rtl: ignore */;
    z-index: @zindex-hack;
    transform: translateZ(1px);

    .is-opacity {
      opacity: 0.6;
    }
  }
  .title{
    font-size: 18px;
    font-weight: 800;
    font-style: normal;
    padding: 0 16/75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: linear-gradient(180deg, #FFF 0%, #FFDE9F 100%);
    /* sh:begin */
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* sh:end */
    /* rw:begin */
    color: #FFF;
    text-shadow: 0px 2px 4px #FD3873;
    background: transparent;
    &.title_club-gift{
      color: #FFF;
      background: none;
      background-clip: initial;
      -webkit-background-clip: initial;
      -webkit-text-fill-color: initial;
      text-shadow: 0 1px 2px rgba(192, 23, 0, 0.60);
    }
  }
  .close-icon{
    position: absolute;
    right: 8/75rem;
    top: 8/75rem;
    z-index: @zindex-hack;
    transform: translateZ(1px);
  }
}
</style>
