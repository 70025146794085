<template>
  <div 
    class="cart-item-behavior"
    :style="styleConfig"
  >
    <img
      :src="info.icon"
      width="13"
      height="13"
    />
    <span class="label-text">
      {{ info.tagName }}
    </span>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ActTag',
  props: {
    info: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    styleConfig() {
      return {
        '--bg-color': this.info.bgColor,
        '--tag-color': this.info.tagColor
      }
    }
  }
})
</script>

<style lang="less" scoped>
.cart-item-behavior {
  overflow: hidden;
  font-size: 12px;
  color: var(--tag-color);
  background-color: var(--bg-color);
  display: flex;
  align-items: center;
  .label-text {
    margin-left: 4/75rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* rtl:begin:ignore */
    direction: ltr;
  }
}
</style>
