<template>
  <div class="promotion-brand__wrapper">
    <div
      v-for="item in brands"
      :key="item.brand_code"
      v-expose="{
        id: '1-8-5-7',
        once: false,
        data: {
          promotionid: promotionId,
          brand_code: item.brand_code,
        }
      }"
      v-tap="{
        id: '1-8-5-8',
        data: {
          promotionid: promotionId,
          brand_code: item.brand_code,
          is_selected: selectedBrandCode == item.brand_code ? 1 : 0
        }
      }"
      :class="['promotion-brand__item', {'selected': selectedBrandCode === item.brand_code}]"
      @click="handleBrandClick(item)"
    >
      {{ item.brand_name }}
    </div>
  </div>
</template>

<script>
import { expose, tap } from 'public/src/pages/common/analysis/directive'
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'BrandFilter',
  directives: {
    expose,
    tap,
  },
  // 组件传参
  props: {
    selectedBrandCode: {
      type: String,
      default: ''
    },
    brands: {
      type: Array,
      default: () => {
        return [
          {
            brand_code: '1',
            brand_name: 'All'
          },
          {
            brand_code: '2',
            brand_name: 'Satisfied'
          },
          {
            brand_code: '3',
            brand_name: 'Unsatisfied'
          }
        ]
      }
    },
    promotionId: {
      type: [String, Number], 
      default: ''
    },
  },
  // 方法实现
  methods: {
    handleBrandClick(brand) {
      brand = this.selectedBrandCode === brand.brand_code ? {} : brand
      this.$emit('change', brand)
    },
  }
})
</script>

<style lang='less' scoped>
.promotion-brand__wrapper{
  padding: 16/75rem 24/75rem 24/75rem;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  .promotion-brand__item{
    // display: inline-block;
    padding: 10/75rem 16/75rem;
    display: inline-flex;
    padding: 5px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 8/75rem;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: @sui_color_gray_dark3;
    background: @sui_color_gray_weak2;
    margin-right: 16/75rem;
    &.selected{
      background: @sui_color_welfare_bg;
      color: @sui_color_discount;
    }
  }
}
</style>
